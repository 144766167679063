
/*
	Theme controls how everything looks in Events Page 08-17-2015.
*/
#holder{
	.event-wrapper{
		background: linear-gradient(to bottom, #b5b5b5 0%, #d8d8d8 2%);
		font-size: 0;
		width: 100%;
		h2{
			font-size: 18px;
			margin-bottom: 0;
		}
		p{
			font-size: 14px;
			margin: 0 0 15px 0;
		}
		.tab-bar{
			background: #444;
			box-sizing: border-box;
			list-style: none;
			margin: 0;
			padding: 15px 20px;
			text-align: center;
			width: 100%;
			.tab-btn{
				background: #666;
				color: #eee;
				cursor: pointer;
				display: inline-block;
				font-size: 16px;
				margin: 0 5px;
				padding: 6px 0 2px 0;
				text-align: center;
				transition: background 0.3s;
				width: 11.2%;
				&.active{
					background: rgba(#805e7f,1);
					&:hover{
						background: rgba(#805e7f,1);
					}
				}
				&:hover{
					background: rgba(#805e7f,0.5);
				}
			}
		}
		.left-col{
			border-right: 1px dotted #333333;
			box-sizing: border-box;
			display: inline-block;
			padding-right: 20px;
			width: 75%;
			vertical-align: top;
			.col-contain{
				display: none;
				width: 100%;	
				&#box-1{
					display: block;
				}
				.event-header{
					border: none;
					margin: 15px 0 0 0;
					padding: 8px 0 2px 8px;
					width: 656px;
				}
				.normal-content{
					background-color: #ccc;
					box-sizing: border-box;
					margin: 0 4% 0 2%;
					padding: 15px 20px;
					width: 94%;
					.inline-contain{
						font-size: 0;
						width: 100%;
						.teams-contain{
							display: inline-block;
							margin: 0 1% 20px 1%;
							vertical-align: top;
							width: 48%;
							.team-bar{
								box-sizing: border-box;
								color: #eee;
								font-size: 15px;
								padding: 15px 20px 11px 20px;
								width: 100%;
								&:nth-child(even){
									background: #999;
								}
								&:nth-child(odd){
									background: #888;
								}
							}
						}
					}
					.t-subhead{
						box-sizing: border-box;
						background: #666;
						color: #eee;
						font-size: 18px;
						padding: 15px 20px 11px 20px;
						text-align: center;
						width: 100%;
					}
					.bracket-holder{
						box-sizing: border-box;
						font-size: 0;
						margin: 20px 0;
						&.left-gap{
							padding-left: 120px;
						}
						.bracket-inline{
							display: inline-block;
							margin-right: 40px;
							width: 200px;
							&:last-child{
								margin-right: 0;
							}
							p{
								margin-bottom: 5px;
							}
							.bracket-bar{
								box-sizing: border-box;
								font-size: 0;
								margin-bottom: 1px;
								padding: 10px;
								width: 100%;
								.left-bar, .right-bar{
									box-sizing: border-box;
									color: #eee;
									display: inline-block;
									font-size: 15px;
									vertical-align: middle;
								}
								.left-bar{
									padding: 0 5px 0 10px;
									text-align: left;
									width: 70%;
								}
								.right-bar{
									border-left: 1px solid #aaa;
									padding: 0 10px 0 5px;
									text-align: left;
									width: 30%;
								}
								&.dark-grey{
									background: #888;
								}
								&.light-grey{
									background: #999;
								}
							}
						}
					}
					.t-standard{
						border-collapse: collapse;
						font-size: 16px;
						margin-bottom: 20px;
						text-align: center;
						width: 100%;
						.groups{
							color: #555;
							tr{
								th{
									padding: 10px 0;
								}	
							}
						}
						tbody{
							color: #eee;
							tr{
								border-bottom: 1px solid #d8d8d8; 
								font-size: 15px;
								&.gold{
									background: #8d8049;
								}
								&.dark-grey{
									background: #888;
								}
								&.light-grey{
									background: #999;
								}
								&.red{
									background: #8e6a6a;
								}
								td{
									border-right: 5px solid #d8d8d8;
									padding: 8px 0;
									&:last-child{
										border-right: none;
									}
								}
							}
						}
					}
				}
			}
		}
		.right-col{
			box-sizing: border-box;
			display: inline-block;
			margin-top: 15px;
			padding: 0 15px;
			text-align: center;
			vertical-align: top;
			width: 25%;
			.side-contain{
				margin-bottom: 20px;
				width: 100%;
				.side-header{
					border: none;
					margin: 0;
					padding: 8px 0 2px 0;
					width: 100%;
				}
				.side-content{
					background-color: #bbb;
					padding: 10px;
					.admin-member{
						margin: 4px 0;
					}
					.group-rank{
						background: #a2a2a2;
						border-radius: 3px;
						color: #333;
						display: block;
						margin: 3px auto;
						padding: 6px 0;
						width: 70%;
					}
				}
				.side-btn{
					background: linear-gradient(to bottom, rgba(#4e2f4f,1) 0%,rgba(#2b152c,1) 100%);
					color: #eee;
					display: block;
					font-size: 21px;
					opacity: 0.94;
					padding: 14px 0 10px 0;
					text-align: center;
					transition: opacity 0.3s;
					width: 200px;
					&.grey-out{
						color: #aaa;
						background: linear-gradient(to bottom, rgba(#666,1) 0%,rgba(#555,1) 100%);
						&:hover{
							opacity: 0.94
						}
					}
					&.alt{
						font-size: 16px;
					}
					.smaller-text{
						display: block;
						color: #ddd;
						font-size: 14px;
						font-weight: thin;
					}
					&:hover{
						opacity: 1;
					}
				}
			}
		}
	}
}
