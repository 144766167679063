@import "variables";

@mixin gallery( $item-count ) {
	$total-duration: $display-duration * $item-count;

	@keyframes controlAnimation-#{$item-count} {
		0% { @include inactive-button; }
	  #{100% * $transition-duration / $total-duration},
	  #{100% * $display-duration / $total-duration} { @include active-button; }
	  #{100% * ($display-duration + $transition-duration) / $total-duration},
	  100% { @include inactive-button; }
	}

	@keyframes galleryAnimation-#{$item-count} {
		0% {
			opacity: 0;
			visibility: hidden;
		}
		#{100% * $transition-duration / $total-duration},
		#{100% * $display-duration / $total-duration} {
			opacity: 1;
			visibility: visible;
		}
		#{100% * ($display-duration + $transition-duration) / $total-duration},
		100% {
			opacity: 0;
			visibility: hidden;
		}
	}

	.gallery {
		@for $j from 1 through $item-count {
			.control-operator:nth-of-type(#{$j}):target ~ .slide:nth-of-type(#{$j}) { pointer-events: auto;
				opacity: 1;
				visibility: visible;
			}
			.control-operator:nth-of-type(#{$j}):target ~ .nav--slides .nav__item:nth-of-type(#{$j}) {
				@extend %active-button;
			}
		}
	}

	.items-#{$item-count} {
		&.autoplay .nav__item {
			animation: controlAnimation-#{$item-count} #{$total-duration}s infinite;
		}
		&.autoplay .slide {
			animation: galleryAnimation-#{$item-count} #{$total-duration}s infinite;
		}

		@for $j from 1 through $item-count {
			.nav__item:nth-of-type(#{$j}),
			.slide:nth-of-type(#{$j}) {
				$delay: #{($j - 1) * ($display-duration) - $transition-duration}s;

				animation-delay: #{$delay};
			}
		}
	}
}

.gallery {
	.slide:first-of-type {
		position: relative;
		pointer-events: auto;
		opacity: 1;
		visibility: visible;
		float: right;
	} // Set the first item to be visible
	.slide {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		pointer-events: auto;
		opacity: 0;
		visibility: hidden;
		transition: opacity .5s;
	}

	.control-operator { display: none; } // Stop hash links from scrolling up and down

	.control-operator:target ~ .slide {
		pointer-events: auto;
		opacity: 0;
		animation: none;
	} // Unselected image

	.nav__item:first-of-type { @extend %active-button; }
	.control-operator:target ~ .nav--slides .nav__item { 
		@extend %inactive-button; animation: none;
	} // Kill button animation when there is a selected image
}
