@import "variables";

/*
	Theme controls how everything looks in Gallery CSS.
*/

.gallery {
	position: relative;
	height: 100%;

	.slide {
		height: 100%;
		width: 100%;
		max-width: 745px;
		position: absolute;
		right: 0;
		box-sizing: border-box;
		> a {
			display: block;
			height: 100%;
			width: 100%;
			color: #fff;
		}
		&__header {
			background: rgba(0, 0, 0, 0.6);
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			padding: 15px;
		}
		&__title {
			font-size: 16px;
			font-weight: bold;
			color: #fff;
			margin: 0 0 -12px;
			position: relative;
		}
		&__readmore {
			float: right;
			margin: -15px 0 0;
		}
	}
	.nav--slides {
		position: absolute;
		left: 0;
	}

	.nav__item {
		display: block;
		max-width: 172px;
		margin: 0 0 6px;
		box-sizing: border-box;
		padding: 2px;
		background: transparent;
		img {
			max-width: 100%;
			vertical-align: bottom;
		}
	}
}