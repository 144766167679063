/*
General Settings
	-Alignment
	-Text
*/
@font-face {
	font-family: 'HammersmithOneRegular';
	src: url('HammersmithOne-webfont.eot');
	src: url('HammersmithOne-webfont.eot?#iefix') format('embedded-opentype'),
		url('HammersmithOne-webfont.woff') format('woff'),
		url('HammersmithOne-webfont.ttf') format('truetype'),
		url('HammersmithOne-webfont.svg#HammersmithOneRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

body, div, h1, h2, h3, img {
	position: inherit;
	margin: 0px auto;
	padding: 0px;
	border: 0px;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	font-size: 12px;
	font-weight: normal;
	text-decoration: none;
	color: #333333;
}

body {
	position: relative;
}

a {
	position: relative;
	margin: 0px auto;
	padding: 0px;
	border: 0px;
	text-decoration: none;
}

p {
	font-family: 'Oxygen', sans-serif;
	font-size: 11px;
}

h1 {
	font-size: 12px;
	position: absolute;
	color: #412C62;
}

h3 {
	font-size: 12px;
	color: #412C62;
	font-family: 'Oxygen', sans-serif;
}

form, input, textarea, select {
	color: #727272;
	font-size: 12px;
	font-family: 'Oxygen', sans-serif;
}

/*
Backgrounds
*/
body {
	background-color: #1F152D;
	background-image: url('/images/hontour-season4-bg.jpg');
	background-repeat: repeat-x;
	background-attachment: fixed;
	/*background changes for HoNtour 3 changes*/
	background-size: cover;
	background-position-x: center;
	border-top:2px solid #151016;

}

.main.left {
	float: left;
	padding-bottom: 15px;
}

.main.left.dark, .main.right.dark {
	background-color: #c3c3c3;
}

.main.right {
	float: right;
	padding-bottom: 15px;
}

.main.left.light, .main.right.light {
	background-color: #d7d7d7;
}

/*
Any Classes Used on Multiple Pages
	-IE:
	-Back to Top
	-Clearfooters
	-Placeholders
	-Headers
	-Chat Icons
	-...
*/

/* Brings you back to the top of the website */
a.backtotop {
	float: right;
	display: block;
	margin: 8px 0 0 0;
	padding: 0;
	width: 521px;
	height: 20px;
	background: url(/images/back_to_top.png) no-repeat left top;
}

a.backtotop:hover {
	background-position: left bottom;
}

.btt {
	width: 210px;
	height: 30px;
	color: white;
	margin-left: 30px;
	line-height: 21px;
}

/* Fixes the length of the element to adjust for floats and absolutes */
.clearfooter {
	clear: both;
}

/* Generic image placeholders */
.placeholder {
	background-color: #7a7083;
	border: 1px solid #4a4259;
}

.placeholder:hover {
	border: 1px solid #2c1b48;
}

.placeholder.banner{
	background-color: #7a7083;
	border: 3px solid #e3b5e3;
	box-shadow: inset 0 0 3px 2px rgba(#000, 0.4);
}

/* Adjusts the forum submit buttons to appear clickable and sit bottom for explorer */
.submit {
	vertical-align: bottom;
	cursor: pointer;
}


/* Purple, Dark and Light Headers */
.header {
	background: #8364b6; /* Old browsers */
	background: -moz-linear-gradient(top, #8364b6 0%, #412c62 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#8364b6), color-stop(100%,#412c62)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #8364b6 0%,#412c62 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #8364b6 0%,#412c62 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #8364b6 0%,#412c62 100%); /* IE10+ */
	background: linear-gradient(to bottom, #8364b6 0%,#412c62 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8364b6', endColorstr='#412c62',GradientType=0 ); /* IE6-9 */
	border: 1px solid #959b9f;
	color: #ffffff;
	font-size: 21px;
	/*height: 27px;*/
	min-height: 27px;
	margin: 20px 12px 0 12px;
	padding: 5px 0 0 8px;
}

.header.light, .fc-header-center {
	background: #efefef; /* Old browsers */
	background: -moz-linear-gradient(top, #efefef 0%, #cccccc 49%, #acacac 50%, #adadad 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#efefef), color-stop(49%,#cccccc), color-stop(50%,#acacac), color-stop(100%,#adadad)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* IE10+ */
	background: linear-gradient(to bottom, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#adadad',GradientType=0 ); /* IE6-9 */
	margin: 0px;
	border: 1px solid #959b9f;
	padding-left: 8px;
	color: #333333;
}

.header.dark {
	background: #505050; /* Old browsers */
	background: -moz-linear-gradient(top, #505050 0%, #292929 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#505050), color-stop(100%,#292929)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #505050 0%,#292929 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #505050 0%,#292929 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #505050 0%,#292929 100%); /* IE10+ */
	background: linear-gradient(to bottom, #505050 0%,#292929 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#505050', endColorstr='#292929',GradientType=0 ); /* IE6-9 */
	border: 1px solid #959b9f;
}

/*  */
.description {
	margin: 0px 12px 5px 12px;
	border-bottom: 1px dotted Black;
}

.description h1 {
	color: #412c62;
	font-weight: normal;
	margin: 10px 0 0 0px;
	font-size: 14px;
}

.description p {
	margin: 0 0 10px 70px;
}

/* Dotted line break */
.dottedline {
	width: 571px;
	position: relative;
	margin: 20px 0 20px 30px;
	border-bottom: 1px dotted #333333;
}

/* */
.more {
	position: absolute;
	color: #6D5398;
}

.more:hover {
	color: #3b275c;
}

/* Chat Button Icons */
.close {
	background-image: url('/images/close.jpg');
	background-position: 0 0;
	width: 10px;
	height: 10px;
}

.close:hover {
	background-position: 0 -10px;
}

.flag {
	background-image: url('/images/flag.png');
	background-position: 0 0;
	width: 10px;
	height: 10px;
}

.flag:hover {
	background-position: 0 -10px;
}

/* Recent Matches */
span .lose {
	text-decoration: line-through;
}

.recent {
	opacity:0.5;
	filter:alpha(opacity=50);
}

/* General Element Settings */
.element {
	font-size: 11px;
	padding: 8px 0;
	color: #333333;
}

.element.light {
	background-color: #7a7586;
}

.element.dark {
	background-color: #524e5a;
}

.element.finals.light {
	background-color: #5d4485;
}

.element.finals.dark {
	background-color: #462f6c;
}

.element.playoffs.light {
	background-color: #7a7586;
}

.element.playoffs.dark {
	background-color: #4f4a5c;
}

.element.navigate {
	background: #4f347a; /* Old browsers */
	background: -moz-linear-gradient(top, #4f347a 50%, #412c62 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#4f347a), color-stop(51%,#412c62)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #4f347a 50%,#412c62 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #4f347a 50%,#412c62 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #4f347a 50%,#412c62 51%); /* IE10+ */
	background: linear-gradient(to bottom, #4f347a 50%,#412c62 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f347a', endColorstr='#412c62',GradientType=0 ); /* IE6-9 */
	height: 20px;
	padding: 4px 0 1px;
	text-align: center;
	color: #ffffff;
}

.element.navigate p {
	font-size: 13px;
}

.element.navigate:hover {
	background: #3f2f60; /* Old browsers */
	background: -moz-linear-gradient(top, #3f2f60 50%, #2d1f43 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#3f2f60), color-stop(51%,#2d1f43)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* IE10+ */
	background: linear-gradient(to bottom, #3f2f60 50%,#2d1f43 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f2f60', endColorstr='#2d1f43',GradientType=0 ); /* IE6-9 */
}

.element.navigate.noclick, .element.navigate.noclick:hover {
	background: #5f5c66; /* Old browsers */
	background: -moz-linear-gradient(top, #5f5c66 50%, #56535d 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#5f5c66), color-stop(51%,#56535d)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* IE10+ */
	background: linear-gradient(to bottom, #5f5c66 50%,#56535d 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f5c66', endColorstr='#56535d',GradientType=0 ); /* IE6-9 */
	color: #9e9e9e !important;
}

.element p {
	margin: 0px;
}

.element.dark p, .element.light p {
	font-size: 13px;
}

.element.dark p, .element.light p,  .element.dark span, .element.light span {
	color: #ffffff;
}

.element .dropdown {
	border-left: 1px dotted #989898;
	height: 18px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 3px;
	width: 30px;
}

.element:hover {
	background-color: #6B5195 !important;
	color: #ffffff !important;
}

.element.finals:hover {
	background-color: #1fb23e;
	color: #ffffff !important;
}

.element.playoffs:hover {
	background-color: #b39d0b;
	color: #ffffff !important;
}

/* Purple and Grey Buttons */
.purple_btn {
	background: #878193; /* Old browsers */
	background: -moz-linear-gradient(top, #878193 50%, #777183 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#878193), color-stop(51%,#777183)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #878193 50%,#777183 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #878193 50%,#777183 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #878193 50%,#777183 51%); /* IE10+ */
	background: linear-gradient(to bottom, #878193 50%,#777183 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#878193', endColorstr='#777183',GradientType=0 ); /* IE6-9 */
	height: 12px;
	margin: 5px;
	padding: 5px;
	border: 1px solid #606060;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
	text-align: center;
	min-width: 100px;
	z-index: 200;
}

.purple_btn {
	float: right;
}

.purple_btn.left {
	float: left;
}

.purple_btn.absolute {
	position: absolute;
}

.purple_btn.green {
	background: #33a23b; /* Old browsers */
	background: -moz-linear-gradient(top, #33a23b 50%, #29832f 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#33a23b), color-stop(51%,#29832f)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #33a23b 50%,#29832f 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #33a23b 50%,#29832f 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #33a23b 50%,#29832f 51%); /* IE10+ */
	background: linear-gradient(to bottom, #33a23b 50%,#29832f 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33a23b', endColorstr='#29832f',GradientType=0 ); /* IE6-9 */
}

.purple_btn:hover, #form .submit:hover, #photoup .browse:hover, #photoup .submit:hover {
	background: #3f2f60; /* Old browsers */
	background: -moz-linear-gradient(top, #3f2f60 50%, #2d1f43 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#3f2f60), color-stop(51%,#2d1f43)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #3f2f60 50%,#2d1f43 51%); /* IE10+ */
	background: linear-gradient(to bottom, #3f2f60 50%,#2d1f43 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f2f60', endColorstr='#2d1f43',GradientType=0 ); /* IE6-9 */
}

.purple_btn.green:hover {
	background: #4dc411; /* Old browsers */
	background: -moz-linear-gradient(top, #4dc411 50%, #2fa814 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#4dc411), color-stop(51%,#2fa814)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #4dc411 50%,#2fa814 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #4dc411 50%,#2fa814 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #4dc411 50%,#2fa814 51%); /* IE10+ */
	background: linear-gradient(to bottom, #4dc411 50%,#2fa814 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dc411', endColorstr='#2fa814',GradientType=0 ); /* IE6-9 */
}

.purple_btn.noclick {
	background: #5f5c66; /* Old browsers */
	background: -moz-linear-gradient(top, #5f5c66 50%, #56535d 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#5f5c66), color-stop(51%,#56535d)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #5f5c66 50%,#56535d 51%); /* IE10+ */
	background: linear-gradient(to bottom, #5f5c66 50%,#56535d 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f5c66', endColorstr='#56535d',GradientType=0 ); /* IE6-9 */
	color: #9e9e9e;
}

#login_btns {
	position: absolute;
	top: 7px;
	right: 6px;
}

.grey_btn {
	float: left;
	background: #808080; /* Old browsers */
	background: -moz-linear-gradient(top, #808080 50%, #6c6c6c 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#808080), color-stop(51%,#6c6c6c)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #808080 50%,#6c6c6c 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #808080 50%,#6c6c6c 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #808080 50%,#6c6c6c 51%); /* IE10+ */
	background: linear-gradient(to bottom, #808080 50%,#6c6c6c 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808080', endColorstr='#6c6c6c',GradientType=0 ); /* IE6-9 */
	height: 12px;
	margin: 5px;
	padding: 5px;
	border: 1px solid #606060;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
	text-align: center;
	min-width: 85px;
}

.grey_btn:hover {
	background: #515151; /* Old browsers */
	background: -moz-linear-gradient(top, #515151 50%, #313131 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#515151), color-stop(51%,#313131)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #515151 50%,#313131 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #515151 50%,#313131 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #515151 50%,#313131 51%); /* IE10+ */
	background: linear-gradient(to bottom, #515151 50%,#313131 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#515151', endColorstr='#313131',GradientType=0 ); /* IE6-9 */
	border: 1px solid #444444;
}

.purp_btn {
	float: left;
	background: #412C62; /* Old browsers */
	background: -moz-linear-gradient(top, #8364B6 50%, #412C62 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#8364B6), color-stop(51%,#412C62)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #8364B6 50%,#412C62 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #8364B6 50%,#412C62 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #8364B6 50%,#412C62 51%); /* IE10+ */
	background: linear-gradient(to bottom, #8364B6 50%,#412C62 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8364B6', endColorstr='#412C62',GradientType=0 ); /* IE6-9 */
	height: 12px;
	margin: 5px;
	padding: 5px;
	border: 1px solid #412C62;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
	text-align: center;
	min-width: 85px;
}

.purp_btn:hover {
	background: #515151; /* Old browsers */
	background: -moz-linear-gradient(top, #515151 50%, #313131 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#515151), color-stop(51%,#313131)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #515151 50%,#313131 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #515151 50%,#313131 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #515151 50%,#313131 51%); /* IE10+ */
	background: linear-gradient(to bottom, #515151 50%,#313131 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#515151', endColorstr='#313131',GradientType=0 ); /* IE6-9 */
	border: 1px solid 412C62;
}

/* Event Division Medals */
.medal {
	position: absolute;
	background-image: url('/images/medals.png');
	height: 92px;
	width: 52px;
}

.medal.bronze {
	background-position: 0 0;	
}

.medal.silver {
	background-position: -52px 0;	
}

.medal.gold {
	background-position: -104px 0;	
}

.medal.platinum {
	background-position: -156px 0;	
}

.medal.diamond {
	background-position: -208px 0;	
}

/* Chat */
.post {
	position: relative;
	background-color: #c1c1c1;
	height: 122px;
	padding-top: 10px;
	margin-top: 15px;
	border: 1px solid #979998;
}

#profile_feed_container .vote {
	display: none;
}

.vote {
	float: left;
	width: 44px;
	height: 65px;
	margin: 8px 5px 0;
}

.vote p {
	font-size: 14px;
	margin-top: 24px;
	text-align: center;
}

.vote .up {
	position: absolute;
	background-image: url('/images/vote_up.jpg');
	background-position: 0 0;
	width: 16px;
	height: 16px;
	top: 13px;
	left: 14px;
	z-index: 20;
	cursor: pointer;
}

.vote .down {
	position: absolute;
	background-image: url('/images/vote_down.jpg');
	background-position: 0 0;
	width: 16px;
	height: 16px;
	bottom: 0px;
	left: 14px;
	z-index: 20;
	cursor: pointer;
}

.vote .up_disabled {
	position: absolute;
	background-image: url('/images/vote_up.jpg');
	background-position: 0 16px;
	width: 16px;
	height: 16px;
	top: 0px;
	left: 14px;
	z-index: 20;
}

.vote .down_disabled {
	position: absolute;
	background-image: url('/images/vote_down.jpg');
	background-position: 0 16px;
	width: 16px;
	height: 16px;
	bottom: 0px;
	left: 14px;
	z-index: 20;
}

.vote .up_selected {
	position: absolute;
	background-image: url('/images/vote_up.jpg');
	background-position: 0 -16px;
	width: 16px;
	height: 16px;
	top: 13px;
	left: 14px;
	z-index: 20;
}

.vote .down_selected {
	position: absolute;
	background-image: url('/images/vote_down.jpg');
	background-position: 0 -16px;
	width: 16px;
	height: 16px;
	bottom: 0px;
	left: 14px;
	z-index: 20;
}

.vote .up:hover, .vote .down:hover {
	background-position: 0 -16px;
}

.post .description {
	margin-top: 8px;
}

.post .description h1 {
	top: 0px;
	left: 0px;
	width: 350px;
	position: relative;
}

.post .description p {
	padding-top: 2px;
}

.foot {
	position: absolute;
	background: #d1cfd0; /* Old browsers */
	background: -moz-linear-gradient(top, #d1cfd0 0%, #adabac 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d1cfd0), color-stop(100%,#adabac)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #d1cfd0 0%,#adabac 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #d1cfd0 0%,#adabac 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #d1cfd0 0%,#adabac 100%); /* IE10+ */
	background: linear-gradient(to bottom, #d1cfd0 0%,#adabac 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1cfd0', endColorstr='#adabac',GradientType=0 ); /* IE6-9 */
	bottom: 0px;
	height: 31px;
	border-top: 1px dotted #333333;
}

.foot p {
	position: absolute;
	margin: 0px;
}

.foot .profile, .foot .team, .foot .match, .foot .report, .foot .delete {
	float: right;
	height: 32px;
	width: 70px;
	border-left: 1px solid #888888;
	color: #6D5398;
}

.foot .profile:hover, .foot .team:hover, .foot .match:hover, .foot .report:hover, .foot .delete:hover {
	color: #ffffff;
	background-color: #3f2f60;
}

.foot .profile p, .foot .team p {
	left: 6px;
	bottom: 7px;
}

.foot .match p {
	left: 7px;
	bottom: 7px;
}

.foot .report p, .foot .delete p{
	right: 23px;
	bottom: 7px;
}

.foot .close, .foot .flag {
	float: right;
	margin: 12px 8px 0 0;
}

/* Multiple Pages */
#pages {
	float: right;
}

#pages span{
	float: left;
	color: #7d5eac;
	padding-top: 2px;
	margin: 0 4px;
	padding-top: 6px;
}

#pages span:hover {
	color: #3f2f60;
}

#pages div {
	float: left;
	background-color: #A6A6A6;
	margin: 4px 2px;
	padding: 3px 4px 0;
	color: #7d5eac;
}

#pages div:hover {
	color: #ffffff;
	background-color: #3f2f60;
}

#pages div.currentpage {
	color: #ffffff;
	background-color: #3F2F60;
}

/* Login to Chat to Enter a Message */
#login_first {
	height: 60px;
	margin: 20px 0px 20px 0;
}

#login_first h1{
	font-size: 23px;
	color: #7d5eac;
}

#login_first p {
	font-size: 15px;
	padding-top: 25px;
}

#login_first a {
	color: #6D5398;
}

#login_first a:hover {
	color: #3b275c;
}

#form {
	position: relative;
	height: 85px;
	margin: 0 0 20px 0;
}

#form h1 {
	bottom: 84px;
	font-size: 18px;
	color: #7D5EAC;
}

#form  form{
	float: left;
	height: 83px;
	background-color: #ffffff;
	border: 1px solid #979998;
}

#form textarea {
	height: 60px;
	background: none;
	border: none;
	resize: none;
	padding: 2px 2px 20px 4px;
}

#form .submit {
	position: absolute;
	background: #878193; /* Old browsers */
	background: -moz-linear-gradient(top, #878193 50%, #777183 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#878193), color-stop(51%,#777183)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #878193 50%,#777183 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #878193 50%,#777183 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #878193 50%,#777183 51%); /* IE10+ */
	background: linear-gradient(to bottom, #878193 50%,#777183 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#878193', endColorstr='#777183',GradientType=0 ); /* IE6-9 */
	right: 0px;
	top: 60px;
	height: 22px;
	margin: 5px;
	padding: 3px;
	border: 1px solid #606060;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
}

#form form p {
	position: absolute;
	top: 70px;
	left: 6px;
	margin: 0px;
	font-size: 10px;
	color: #aaaaaa;
}

#form .rules {
	float: right;
	margin-top: 0px;
}

/* Information Blocks */
.info_block {
	background-color: #B6B6B6;
}

.info_type {
	color: #4B425A;
	float: left;
	font-size: 16px;
	padding-right: 7px;
	text-align: right;
	width: 164px;
	margin-top: 4px;
}

.info_fillin {
	float: left;
	font-family: 'Oxygen', sans-serif;
	font-size: 13px;
	width: 374px;
	margin-top: 4px;
}

#edit_info textarea {
	resize: none;
}

#edit_info .name, #edit_info .experience, #edit_info .accomplishments, #edit_info .fav_team, #edit_info .fav_player, #edit_info .fav_food, #edit_info .hobby_list, #edit_info .system_specs {
	width: 99%;
}

#edit_info .age {
	width: 100px;
}

#edit_info .experience {
	height: 60px;
}

#edit_info .accomplishments {
	height: 60px;
}

#edit_info .hobby_list {
	height: 60px;
}

#edit_info .system_specs {
	height: 390px;
}

/* Events Ticker */

#matchticker{
	.ticker-title{
		background: #4f4a5c;
		color: #fff;
		padding: 1px 8px;
		text-transform: uppercase;
		span{
			box-sizing: border-box;
			font-size: 14px;
			width: 100%;
		}
	}
	.element{
		height: auto;
		.light{
			p{
				padding: 0;
			}
		}
		.ticker-inline{
			font-size: 0;
			width: 100%;
			.ticker-left{
				border-right: 1px dotted #aaa;
				box-sizing: border-box;
				display: inline-block;
				padding-right: 7px;
				vertical-align: middle;
				width: 60%;
			}
			.ticker-right{
				box-sizing: border-box;
				display: inline-block;
				padding-left: 7px;
				vertical-align: middle;
				width: 40%;
			}
		}
	}
}

/* Featured News and Announcements */
.longbox {
	position: relative;
	height: 182px;
	background-color: #7a7586;
	padding-top: 7px;
	margin: 10px 0 15px 0;
}

.longbox-img{
	height: 126px;
	background-color: #b7b7b7;
}

.longbox-title{
	color: #ffffff;
	font-size: 14px;
	padding: 10px 0 0 10px;
	font-family: 'Oxygen', san-serif;
	font-weight: bold;
}

.longbox-body{
	padding: 0 10px 10px 10px;
	color: #CAC9CC;
	text-align: justify;
	font-family: 'Oxygen' , sans-serif;
	font-size: 12px;
}

.longbox-link{
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 200px;
	text-align: right;
	color: #ffffff;
	padding-right: 10px;
}

.longbox-link a{
	text-align: right;
	color: #ffffff;
	bottom: 5px;
}

.longbox-link a:hover, .sqbox-link a:hover{
	text-decoration: underline;
}

.longbox-datebox {
	background-image: url('/images/date.png');
	width: 67px;
	height: 44px;
	float: left;
	margin-top: 8px;
	color: white;
	font-size: 22px;
	text-align: center;
	padding-top: 4px;
}

.longbox-date {
	width: 30px;
	height: 30px;
	float: left;
	margin-left: -59px;
	font-size: 18px;
	text-align: center;
	margin-top: 13px;
	color: white;
}

/* Squared */
.sqbox {
	position: relative;
	background-color: #4A494E;
	padding-top: 7px;
	float: left;
}

.sqbox-img {
	height: 102px;
	background-color: #B7B7B7;
}

.sqbox-title{
	color: #ffffff;
	font-size: 14px;
	padding: 6px 0 0 2px;
	font-family: 'Oxygen', sans-serif;
	font-size: 12px; 
	font-weight: bold;
}

.sqbox-body{
	padding: 0 13px 10px;
	color: #cac9cc;
	text-align: left;
	font-family: 'Oxygen', sans-serif;
	font-size: 12px; 	
}

.sqbox-link {
	position: absolute;
	bottom: 5px;
	right: 0px;
	width: 200px;
	text-align: right;
	color: #ffffff;
	padding-right: 10px;
}

.sqbox-link a{
	text-align: right;
	color: #ffffff;
}

/* Recent News */
#recentnews {
	min-height: 190px;
}

#upcoming .element .dropdown img, #recent .element .dropdown img {
	position: relative;
}

#recentnews .element .dropdown img {
	margin-top: 5px;
	position: relative;
}

#recentnews .element {
	background-color: #4A494E;
	height: 24px;

	padding: 6px 0 0 6px;
	color: #ffffff;
}

#upcoming .element, #recent .element {
	background-color: #b6b6b6;
	height: 19px;
	padding: 6px 0 0 6px;
}

/* Upcoming - Recent Matches */
#upcoming .header, #recent .header {
	padding: 0;
	min-height: 32px;
}

.header.day {
	padding: 5px 0 0 8px !important;
	min-height: 27px !important;
}

.header .up {
	float: left;
	width: 48%;
	padding-left: 10px;
	padding-top: 8px;
	position: absolute;
	left: 0;
	top: 0;
}

.header .re {
	float: right;
	width: 46%;
	padding-left: 8px;
	padding-top: 8px;
	position: absolute;
	right: 0;
	top: 0; 
}

.header .selected {
	position: relative;
	color: #ffffff;
	font-size: 15.5px;
	height: 24px;
}

.header .unselected {
	position: relative;
	background-color: #2F1F49;
	color: #ffffff;
	font-size: 15.5px;
	height: 24px;
	cursor: pointer;
}

.header .unselected:hover {
	background: #58467f; /* Old browsers */
	background: -moz-linear-gradient(top, #58467f 0%, #2e2149 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#58467f), color-stop(100%,#2e2149)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #58467f 0%,#2e2149 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #58467f 0%,#2e2149 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #58467f 0%,#2e2149 100%); /* IE10+ */
	background: linear-gradient(to bottom, #58467f 0%,#2e2149 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58467f', endColorstr='#2e2149',GradientType=0 ); /* IE6-9 */
	font-size: 15.5px;
	height: 24px;
}

.element .purplebar {
	float: left;
	background-color: #795ca8;
	width: 3px;
	height: 26px;
	margin-left: 4px;
}

.element .region {
	float: left;
	margin: 0 6px 0 4px;
	bottom: 4px;
}

.element .text {
	margin: 0px;
	padding-top: 2px;
}

.element .scheduleTime {
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	font-size: 14pt;
	line-height: 24pt;
	margin: 0 0 0 10px;
	padding-top: 2px;
}

.element .time {
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	font-size: 14.5px;
	margin: 0;
	position: absolute;
	right: 42px;
	top: 11px;
}

#recent {
	display: none;
}

/* Photo Scrolling */
#scrolling div {
	background-image: url('/images/scrolling.png');
	width: 27px;
	height: 76px;
}

#scroll_left {
	float: left;
	background-position: 0 0;
}

#scroll_right {
	float: right;
	background-position: -27px 0;
}

#scroll_left:hover {
	background-position: 0 -76px;
}

#scroll_right:hover {
	background-position: -27px -76px;
}

/*
Main Page Setup
	-Content
	-Main Page Content Holder
*/
#content {
	width: 960px;
	min-height: 602px;
	padding: 80px 0 40px 0;
}

#holder {
	background: none;
	width: 925px;
}
#holder.home {
	margin-top: 340px;
}

/*
Navigation Bar
	-Logo
	-Buttons
	-Search
*/
#logo {
	position: absolute;
	background-image: url('/images/hontour-sheild.png');
	background-position: 0 0;
	width: 198px;
	height: 190px;
	top: 20px;
	left: -70px;
	z-index: 30;
	color: transparent;
}

#logo:hover {
	background-position: 0 -190px;
}

#nav {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e2f4e+0,2c142c+99 */
	background: #4e2f4e; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRlMmY0ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk5JSIgc3RvcC1jb2xvcj0iIzJjMTQyYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(top,  #4e2f4e 0%, #2c142c 99%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4e2f4e), color-stop(99%,#2c142c)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #4e2f4e 0%,#2c142c 99%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #4e2f4e 0%,#2c142c 99%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #4e2f4e 0%,#2c142c 99%); /* IE10+ */
	background: linear-gradient(to bottom,  #4e2f4e 0%,#2c142c 99%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e2f4e', endColorstr='#2c142c',GradientType=0 ); /* IE6-8 */
	width: 925px;
	height: 55px;
	box-shadow: 0 0 20px #222222;
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: 20;
}
#nav > a {
	display: inline-block;
	line-height: 55px;
	height: 55px;
	font-size: 17px;
	color: #fff;
	padding: 0 18px;
}
#nav > a:hover, #nav > a:active {
	color: #e3b5e3;
}
#nav > a:first-child {
	margin-left: 115px;
}
.button {
	float: left;
	height:55px;
	color: #FFF;
	font-size: 20px;
	text-shadow: 1px 1px 1px #000;
	text-align: center;
	padding: 18px 10px;
	margin: 0 auto;
}

.button div {
	position: absolute;
	width: 100%;
	bottom: 50px;
	right: 0px;
}

.button:hover {
	background-position:0 0px;
	color: #ffffff;
	text-shadow: 1px 1px 1px #000000;
}

#nav form {
	position: absolute;
	right: 10px;
	bottom: 13px;
}

#nav form .text, #event form .text {
	border-radius: 5px;
	border: 1px solid #D2D2D2;
	width: 143px;
	height: 25px;
	padding-left: 5px;
}

#nav form .submit, #event form .submit {
	background: url("/images/search_glass.jpg") no-repeat scroll center center transparent;
	background-position: -3px -2px;
	border-radius: 5px;
	border: 1px solid #707070;
	width: 45px;
	height: 28px;
	margin-left: 5px;
	cursor: pointer;
}

#nav form .submit:hover, #event form .submit:hover {
	background-position: -3px -32px;
	border: 1px solid #444444;
}

/*
Account Login
*/
#account {
	position: relative;
	background: #434343; /* Old browsers */
	background: -moz-linear-gradient(top, #434343 0%, #333333 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#434343), color-stop(100%,#333333)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #434343 0%,#333333 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #434343 0%,#333333 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #434343 0%,#333333 100%); /* IE10+ */
	background: linear-gradient(to bottom, #434343 0%,#333333 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#434343', endColorstr='#333333',GradientType=0 ); /* IE6-9 */
	width: 925px;
	height: 48px;
	box-shadow: 0 3px 2px #666666;
	z-index: 10;
}

#account p {
	color: #ffffff;
	font-size: 13px;
	margin: 0px;
	padding: 15px 0 0 100px;
}

#account p  a:hover {
	color: #6D5398;
}

.semibold {
	margin-right: 5px;
}

.recolor {
	color: #c8bde9;
	text-decoration: underline;
}

#account form {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

#account form .text {
	border-radius: 5px;
	border: 1px solid #D2D2D2;
	width: 125px;
	height: 25px;
	padding-left: 5px;
	margin-left: 5px;
}

#account form .submit {
	background: url("/images/login.jpg") no-repeat scroll center center transparent;
	background-position: -3px -2px;
	border-radius: 5px;
	border: 1px solid #707070;
	width: 45px;
	height: 30px;
	margin-left: 5px;
}

#account form .submit:hover {
	background-position: -3px -32px;
	border: 1px solid #444444;
}

/*
Footer
	-Links
	-Credits
*/
#footer {
	position: relative;
	background-color: #18151c;
	height: 114px;
	border-top: 12px solid #2d2c2f;
	padding: 7px 0 0 6px;
}

#footer .button {
	background: none;
	color: #ffffff;
	text-shadow: none;
	font-size: 15px;
	border-right: 1px solid #2D2C2F;
	padding: 11px;
	height: 20px;
}

#footer .button.selected {
	color: #888888;
}

#footer .button:hover {
	box-shadow: none;
	color: #7d5fae;
}

#footer .button div {
	bottom: 10px;
}

#footer .dottedline {
	border-bottom: 1px dotted #656565;
	left: 0px;
	top: 35px;
	width: 740px;
}

#footer p {
	position: absolute;
	color: #FFFFFF;
	font-size: 10px;
	margin: 0px;
	left: 10px;
	bottom: 20px;
}

#footer span {
	color: #2d2c2f;
}

#footer a {
	color: #7d5fae;
	text-decoration: none;
}

#footer a:hover {
	color: #ffffff;
	text-decoration: none;
}

#logos {
	float: right;
	margin: -17px 23px 0 0;
}

/* Common Profile Stuff */
#profileID {
	position: relative;
	background: #795da8; /* Old browsers */
	background: -moz-linear-gradient(top, #795da8 0%, #3b275c 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#795da8), color-stop(100%,#3b275c)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #795da8 0%,#3b275c 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #795da8 0%,#3b275c 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #795da8 0%,#3b275c 100%); /* IE10+ */
	background: linear-gradient(to bottom, #795da8 0%,#3b275c 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#795da8', endColorstr='#3b275c',GradientType=0 ); /* IE6-9 */
}

#profileID .placeholder {
	position: absolute;
	z-index: 20;
}

#profileID .tab {
	position: absolute;
	background-image: url('/images/profile_IDtab.jpg');
	background-position: 0 0;
	width: 146px;
	height: 24px;
	color: #ffffff;
	margin-top: 256px;
	left: 39px;
	z-index: 20;
	padding: 8px 0 0 67px;
	font-size: 18px;
	border-radius: 0 0 7px 7px;
	cursor: pointer;
}

#profileID .tab:hover {
	background-position: 0 -32px;
}

#profileID #last_online {
	position: absolute;
	color: #ffffff;
}

.followers {
	width: 253px;
	max-height: 150px;
}

.followers .placeholder {
	float: left;
	width: 51px;
	height: 51px;
	margin: 5px;
}

.followers p {
	position: absolute;
	margin: 0px;
	width: 120px;
}

.main.right #view_posts {
	height: 20px;
}

.main.right #view_posts div {
	bottom: 10px;
	right: 23px;
}

.main.right .post.recentMatch {
	height: 90px;
	padding: 0;
}

.main.right .post.recentMatch p {
	padding-left: 10px;
}

.main.right .post.recentMatch .foot p {
	padding-left: 0px;
}

.main.right .post.recentMatch .medal {
	right: 4px;
	top: -38px;
}

.main.right .post.upcomingMatch {
	height: 73px;
	padding: 0;
}

.main.right .post.upcomingMatch .element {
	height: 33px;
	padding-top: 7px;
	background-color: #c1c1c1;
	color:#333333;
}

.main.right .post.upcomingMatch .element .purplebar {
	float: left;
	background-color: #795ca8;
	width: 3px;
	height: 26px;
	margin-left: 4px;
}

.main.right .post.upcomingMatch .element .region {
	float: left;
	margin: 0 6px 0 4px;
	bottom: 4px;
}

.main.right .post.upcomingMatch .element .text {
	margin: 0px;
	padding-top: 2px;
}

.main.right .post.upcomingMatch .element .time {
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	font-size: 14.5px;
	margin: 0;
	position: absolute;
	right: 42px;
	top: 11px;
}

.main.right #photoup {
	height: 170px;
	margin: 30px 0 20px 30px;
	width: 571px;
}

.main.right #photoup .text {
	width: 490px;
	height: 20px;
}

.main.right #photoup h1 {
	position: relative;
	font-size: 18px;
	color: #7D5EAC;
}

.main.right #photoup form{
	position: relative;
	float: left;
	height: 40px;
	width: 590px;
}

.main.right #photoup .browse {
	position: absolute;
	background: #878193; /* Old browsers */
	background: -moz-linear-gradient(top, #878193 50%, #777183 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#878193), color-stop(51%,#777183)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #878193 50%,#777183 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #878193 50%,#777183 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #878193 50%,#777183 51%); /* IE10+ */
	background: linear-gradient(to bottom, #878193 50%,#777183 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#878193', endColorstr='#777183',GradientType=0 ); /* IE6-9 */
	right: 0px;
	top: -3px;
	height: 22px;
	margin: 5px;
	padding: 3px;
	border: 1px solid #606060;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
}

.main.right #photoup .submit {
	position: absolute;
	background: #878193; /* Old browsers */
	background: -moz-linear-gradient(top, #878193 50%, #777183 51%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#878193), color-stop(51%,#777183)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #878193 50%,#777183 51%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #878193 50%,#777183 51%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #878193 50%,#777183 51%); /* IE10+ */
	background: linear-gradient(to bottom, #878193 50%,#777183 51%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#878193', endColorstr='#777183',GradientType=0 ); /* IE6-9 */
	right: 33px;
	top: -5px;
	height: 22px;
	margin: 5px;
	padding: 3px;
	border: 1px solid #606060;
	color: #ffffff;
	font-family: 'HammersmithOneRegular', 'Hammersmith One', sans-serif;
	border-radius: 3px;
}

.main.right #photoup form p {
	position: absolute;
	top: 70px;
	left: 6px;
	margin: 0px;
	font-size: 10px;
	color: #aaaaaa;
}

.main.right #photoup .rules {
	position: relative;
	float: right;
	top: -40px;
}

.main.right #gallery {
	width: 571;
	margin: 20px 0 20px 30px;
}

.main.right #gallery .placeholder {
	float: left;
	width: 81px;
	height: 81px;
	margin: 7px;
}

.main.right #gallery .placeholder.selected {
	border: 1px solid #8364B6;
}

/*
Countdown Timer Preloading
*/
/* small size */
.flip-1.small {
	background-image: url("small/1.png");
}

.flip-2.small {
	background-image: url("small/2.png");
}

.flip-3.small {
	background-image: url("small/3.png");
}

.flip-4.small {
	background-image: url("small/4.png");
}

.flip-5.small {
	background-image: url("small/5.png");
}

.flip-6.small {
	background-image: url("small/6.png");
}

.flip-7.small {
	background-image: url("small/7.png");
}

.flip-8.small {
	background-image: url("small/8.png");
}

.flip-9.small, .flip.small  {
	background-image: url("small/9.png");
}

.flip-0.small {
	background-image: url("small/0.png");
}

/*
DAILY SCHEDULE POPUP
*/
#popup {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 999;
}

#popup #bg {
	position: relative;
	background-color: #000000;
	opacity:0.7;
	filter:alpha(opacity=70); /* For IE8 and earlier */
	width: 100%;
	height: 100%;
}

#popup #sch_holder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}

#sch_window #sch_header {
	height: 28px;
}

#popup #sch_header .close_me {
	background-image: url('../images/close_me.jpg');
	background-position: right top;
	background-repeat: no-repeat;
	width: 19px;
	height: 19px;
	float: right;
	margin: 5px 5px 0 0;
	padding: 5px 36px 0 0;
	color: #5c487f;
	cursor: pointer;
}

#popup #sch_header .close_me:hover {
	background-image: url('../images/close_me_hover.jpg');
	color: #b49adb;
}

#popup #sch_header .view_date {
	float: left;
	color: #333333;
	font-family: 'Oxygen', sans-serif;
	font-size: 11px;
	left: 14px;
	top: 36px;
}

#popup #sch_header .last_updated {
	float: right;
	color: #333333;
	font-family: 'Oxygen', sans-serif;
	font-size: 11px;
	left: 46px;
	top: 36px;
}

#popup #sch_window {
	background-color: #d8d8d8;
	position: relative;
	width: 478px;
	margin: auto;
	border: 6px solid #9e9e9e;
	margin-top: 55px;
}

#popup .header {
	font-size: 26px;
	padding: 2px 0 0 8px;
	height: 28px;
	margin-top: 5px;
}

#popup .header .small{
	bottom: 1px;
	font-size: 14px;
	left: 6px;
	position: relative;
}

#popup .header .expand {
	background-image: url('../images/expand.jpg');
	background-position: 0 0;
	width: 19px;
	height: 19px;
	float: right;
	margin: 3px 6px 0 0;
	cursor: pointer;
}
#popup .header .expand:hover {
	background-position: -38px 0;
}

#popup .header .expand.noclick {
	background-position: -19px 0;
}

#popup #sch_window #upcoming {
	margin-top: 5px;
}

#popup #sch_window #upcoming .element {
	height: 25px;
	margin: 5px 20px 0;
}

#popup #sch_window #upcoming .element p {
	padding: 5px 0 0 7px;
}

#popup #sch_window #upcoming .element.dark p, #popup #sch_window #upcoming .element.light p {
	padding: 4px 0 0 7px;
}

#popup #sch_window #upcoming .element {
	height: 33px;
}

#popup #sch_window #sch_footer {
	background: #efefef; /* Old browsers */
	background: -moz-linear-gradient(top, #efefef 0%, #cccccc 49%, #acacac 50%, #adadad 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#efefef), color-stop(49%,#cccccc), color-stop(50%,#acacac), color-stop(100%,#adadad)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* IE10+ */
	background: linear-gradient(to bottom, #efefef 0%,#cccccc 49%,#acacac 50%,#adadad 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#adadad',GradientType=0 ); /* IE6-9 */
	margin: 10px auto;
	border: 1px solid #959b9f;
	padding: 7px 0 0 12px;
	color: #333333;
	width: 136px;
	height: 25px;
	cursor: pointer;
	font-size: 17px;
}

#popup #sch_window #sch_footer:hover {
	background: #fcfcfc; /* Old browsers */
	background: -moz-linear-gradient(top, #fcfcfc 49%, #cccccc 50%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(49%,#fcfcfc), color-stop(50%,#cccccc)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #fcfcfc 49%,#cccccc 50%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #fcfcfc 49%,#cccccc 50%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #fcfcfc 49%,#cccccc 50%); /* IE10+ */
	background: linear-gradient(to bottom, #fcfcfc 49%,#cccccc 50%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}

/* Flag Icons */
.placeholder.flag {
	background-image: url('../images/flags.png');
	width: 18px;
	height: 11px;
}

.placeholder.flag.argentina{
	background-position: -1px -1px;
}

.placeholder.flag.australia{
	background-position: -22px -1px;
}

.placeholder.flag.austria{
	background-position: -41px -1px;
}

.placeholder.flag.belgium{
	background-position: -61px -1px;
}

.placeholder.flag.brazil{
	background-position: -81px -1px;
}

.placeholder.flag.bulgaria{
	background-position: -101px -1px;
}

.placeholder.flag.canada{
	background-position: -122px -1px;
}

.placeholder.flag.chile{
	background-position: 0px -14px;
}

.placeholder.flag.czech_republic{
	background-position: -21px -14px;
}

.placeholder.flag.denmark{
	background-position: -41px -14px;
}

.placeholder.flag.finland{
	background-position: -61px -14px;
}

.placeholder.flag.france{
	background-position: -81px -14px;
}

.placeholder.flag.germany{
	background-position: -101px -14px;
}

.placeholder.flag.greece{
	background-position: -121px -14px;
}

.placeholder.flag.hungary{
	background-position: 0px -27px;
}

.placeholder.flag.indonesia{
	background-position: -21px -27px;
}

.placeholder.flag.italy{
	background-position: -41px -27px;
}

.placeholder.flag.japan{
	background-position: -61px -27px;
}

.placeholder.flag.netherlands{
	background-position: -81px -27px;
}

.placeholder.flag.new_zealand{
	background-position: -101px -27px;
}

.placeholder.flag.norway{
	background-position: -121px -27px;
}

.placeholder.flag.peru{
	background-position: -1px -41px;
}

.placeholder.flag.poland{
	background-position: -21px -40px;
}

.placeholder.flag.portugal{
	background-position: -41px -40px;
}

.placeholder.flag.romania{
	background-position: -61px -40px;
}

.placeholder.flag.russian_federation{
	background-position: -81px -40px;
}

.placeholder.flag.slovakia{
	background-position: -101px -40px;
}

.placeholder.flag.south_africa{
	background-position: -121px -40px;
}

.placeholder.flag.spain{
	background-position: -2px -53px;
}

.placeholder.flag.sweden{
	background-position: -22px -53px;
}

.placeholder.flag.turkey{
	background-position: -41px -53px;
}

.placeholder.flag.ukraine{
	background-position: -61px -53px;
}

.placeholder.flag.united_kingdom{
	background-position: -82px -53px;
}

.placeholder.flag.united_states{
	background-position: -101px -52px;
}

.placeholder.flag.europe{
	background-position: -121px -53px;
}

.placeholder.flag.1{
	background-position: -121px -53px;
}

.placeholder.flag.2{
	background-position: 0px -66px;
}

.placeholder.flag.3{
	background-position: -21px -66px;
}

.placeholder.flag.4{
	background-position: -41px -66px;
}

.placeholder.flag.5{
	background-position: -61px -66px;
}

.placeholder.flag.6{
	background-position: -81px -66px;
}

.placeholder.flag.7{
	background-position: -101px -66px;
}

.placeholder.flag.8{
	background-position: -121px -66px;
}
::selection		{ background:#8e7ea7; color:#fff; /* Safari and Opera */ }
::-moz-selection	{ background:#8e7ea7; color:#fff; /* Firefox */ }

#slides {
	position: absolute;
	margin-top: -320px;
	width: 100%;
	height: 300px;
}
@import "gallery/gallery.theme.scss";
@import "gallery/gallery.build.scss";
@import "events/events.theme.scss";